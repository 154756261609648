
















































import Vue from "vue";
import * as models from "@/models";
import { lookups } from "@/code/api.lookups";
// import { rules } from "@/globals";

export default {
  name: "countryCitySelector",
  props: {
    value: {
      required: false,
      type: Object as () => models.city
    },
    box: {
      type: Boolean,
      required: false,
      default: false
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    },
    light: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false
    },
    countryLabel: {
      type: String,
      required: false,
      default: "Country"
    },
    cityLabel: {
      type: String,
      required: false,
      default: "City"
    }
  },
  computed: {
    isRequired: function() {
      if (!this.initialized) return false;
      return !!this.$el.attributes.getNamedItem("required");
    },
    boxMode: function(): boolean {
      return !(this.box === undefined || this.box == false);
    },
    lightMode: function(): boolean {
      return !(this.light === undefined || this.light == false);
    },
    darkMode: function(): boolean {
      return !(this.dark === undefined || this.dark == false);
    }
  },
  data() {
    return {
      initialized: false,
      selectedCity: <models.city>null,
      selectedCountry: <models.country>null,
      countryCities: <models.country>{ cities: [] },
      countries: <models.country[]>[],
      rules: {
        required: (value: any) => {
          let This = <any>this;
          if (!This.initialized) return true;
          return !This.isRequired || !!value || "Required";
        }
      }
    };
  },
  watch: {
    selectedCountry: function(
      newValue: models.country,
      oldValue: models.country
    ) {
      if (!this.initialized) return;
      // console.log({
      //   selectedCountry: "selectedCountry",
      //   newValue: newValue,
      //   oldValue: oldValue
      // });
      // console.log("C watched");

      if (newValue != oldValue) {
        this.selectedCity = null;
        this.updateValue(null);
        if (newValue) this.loadCities(newValue.code);
        else this.countryCities.cities = [];
      }
    }
  },
  created() {},
  async mounted() {
    let countriesLoaded = lookups.countries().then(cns => {
      this.countries = cns;
    });

    if (this.value) {
      // console.log(this.value.countryCode);
      let initialCitiesLoaded = this.loadCities(this.value.countryCode);

      await Promise.all([countriesLoaded, initialCitiesLoaded]).then(() => {
        // console.log("this.value.countryCode");
        // console.log(this.value.countryCode);
        this.selectedCountry = this.countries.find(
          cn => cn.code == this.value.countryCode
        );
        this.selectedCity = this.value;
      });
    } else {
      await countriesLoaded;
    }

    this.initialized = true;
  },
  methods: {
    loadCities: function(countryCode: string) {
      return lookups.cities(countryCode).then(cn => {
        this.countryCities = cn;
      });
    },
    updateValue(newValue: models.city) {
      this.$emit("input", newValue);
    }
  }
};
