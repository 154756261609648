























































































import Vue from "vue";
import * as models from "@/models";
import { G /*, rules */ } from "@/globals";
import jobCard from "@/components/jobs/jobCard.vue";
import { jobs } from "@/code/api.jobs.orgs";
import seekerCardsList from "@/components/seekers/seekerCardsList.vue";

declare type viewMode = "create" | "edit";

export default {
  name: "Orgs_JobPost",
  components: {
    jobCard,
    seekerCardsList
  },
  props: ["JobPost"],
  data: () => ({
    currentTab: 0,
    isSaving: false,
    jobPost: <models.jobPostExtended>null,
    initializing: true,
    currentLabelIndex: 0,
    windows: ["Candidates", "Info"],
    selectedWindow: null
  }),
  watch: {
    jobPost: function(newValue: models.jobPost) {
      if (!newValue) return;
    }
  },
  computed: {
    pageTitle: function(): string {
      return this.jobPost ? this.jobPost.title : "Job Post";
    },
    seekers: function(): models.seekerProfile[] {
      let jp: models.jobPostExtended = this.jobPost;
      if (
        !jp ||
        !jp.context ||
        !jp.context.recruiter ||
        !jp.context.recruiter.labelGroups ||
        jp.context.recruiter.labelGroups.length == 0
      )
        return [];

      // console.log(jp.context.recruiter.labelGroups);

      let labelGroup = jp.context.recruiter.labelGroups.find(
        lg => lg.labelContent == this.predefinedLabels[this.currentLabelIndex]
      );

      // console.log(labelGroup);
      if (labelGroup) {
        return labelGroup.seekerLabels.map(sl => sl.seekerProfile);
      } else {
        return [];
      }
    },
    predefinedLabels: function():
      | {
          content: string;
          color: string;
          icon: string;
        }[]
      | string[] {
      let lbls = models.predefinedLabels.labels;
      return [lbls.Applied, lbls.Shortlisted, lbls.Ignored].map(l => l.content);
    }
  },
  created: function() {
    // G.data.customTitle = "Job Post";
  },
  mounted: function() {
    try {
      G.showLoading();
      if (this.passedJobPost) {
        //PostJob Post object was passed
        this.jobPost = this.passedJobPost;
      } else {
        // No object passed
        let qsId = <string>this.$route.query.id;
        // console.log(qsId);

        if (this.$route.query.id && !isNaN(parseInt(qsId))) {
          //JP Id was passed
          let jpId: number;
          // view OR edit
          jpId = parseInt(qsId);
          jobs.orgs.posts.getById(jpId).then(jpx => (this.jobPost = jpx));
        } else {
          // no post avialvle, go to org home
          this.$router.push({
            name: "Orgs_JobPosts"
          });
        }
      }
    } finally {
      G.hideLoading();
    }
  },
  methods: {
    seekerCardClick: function(sp: models.seekerProfile) {
      this.$router.push({
        name: "Orgs_Discover__SeekerProfile",
        params: <any>{
          passedProfile: sp,
          id: sp.id
        }
      });
    }
  }
};
