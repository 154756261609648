import { server } from "@/code/server";
import * as models from "@/models";
import { G } from "@/globals";

export class organizations {
  static register = (
    organizationName: string,
    industryId: number,
    cityId: string
  ) => {
    return server.Post<models.org>(
      "organizations/register",
      {
        organizationName: organizationName,
        industryId: industryId,
        cityId: cityId
      },
      true
    );
  };

  // static getMyOrganizations = async (
  //   lm: models.loadMode,
  //   postUpdateCallback?: any
  // ): Promise<models.org[]> => {
  //   //  if (!lm) throw new Error("Fix getMyOrganizations calls");
  //   switch (lm) {
  //     case models.loadMode.remote:
  //       return server
  //         .Post<models.org[]>("organizations/getMyOrganizations", null, true)
  //         .then(orgs => {
  //           G.localDb
  //             .db()
  //             .orgs.clear()
  //             .then(() => {
  //               G.localDb.db().orgs.bulkPut(orgs);
  //             });
  //           return orgs;
  //         });

  //     case models.loadMode.localAndUpdate:
  //       let localResult = await G.localDb.db().orgs.toArray();

  //       let serverResult = server
  //         .Post<models.org[]>("organizations/getMyOrganizations", null, true)
  //         .then(orgs => {
  //           if (postUpdateCallback) postUpdateCallback(orgs);
  //           G.localDb
  //             .db()
  //             .orgs.clear()
  //             .then(() => {
  //               G.localDb.db().orgs.bulkPut(orgs);
  //             });
  //           return orgs;
  //         });
  //       if (localResult.length == 0) {
  //         return await serverResult;
  //       } else {
  //         return localResult;
  //       }

  //     case models.loadMode.local:
  //       return G.localDb.db().orgs.toArray();
  //   }
  // };

  static getMyOrganizations = async (): Promise<models.org[]> => {
    //  if (!lm) throw new Error("Fix getMyOrganizations calls");

    return server
      .Post<models.org[]>("organizations/getMyOrganizations", null, true)
      .then(orgs => {
        G.localDb
          .db()
          .orgs.clear()
          .then(() => {
            G.localDb.db().orgs.bulkPut(orgs);
          });
        return orgs;
      });
  };
}
