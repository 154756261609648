var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "t-msg-layout fill-height xdark",
      attrs: { "fill-height": "" }
    },
    [
      _c(
        "div",
        { staticClass: "t-msg-list baby-scroll" },
        [
          !_vm.initializing
            ? [
                _c(
                  "div",
                  { staticClass: "t-msg-item text-xs-center" },
                  [
                    _vm.pagedMessages.hasNext
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              block: "",
                              round: "",
                              disabled: _vm.loadingMore,
                              loading: _vm.loadingMore,
                              flat: "",
                              color: "accent darken-4",
                              darkc: ""
                            },
                            on: { click: _vm.loadMore }
                          },
                          [_vm._v("Load More")]
                        )
                      : _vm._e(),
                    !_vm.pagedMessages.hasNext
                      ? _c("span", { staticClass: "grey--text" }, [
                          _vm._v("- start -")
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "t-msg-divider grey darken-3" })
              ]
            : _vm._e(),
          _vm._l(_vm.pagedMessages.items, function(msg, index) {
            return [
              _c(
                "div",
                {
                  key: msg.id,
                  staticClass: "t-msg-item",
                  class: msg.isMine ? "me" : "",
                  attrs: { "data-msg-id": msg.id }
                },
                [
                  _c("h3", { staticClass: "primary--text text--lighten-2" }, [
                    _vm._v(_vm._s(_vm.getMessageHeader(msg)))
                  ]),
                  _c(
                    "div",
                    { staticClass: "t-msg-item-body" },
                    [
                      !msg.video
                        ? _c("p", {
                            staticClass: "primary--text",
                            class: {
                              "font-weight-bold accent--text text--darken-2":
                                msg.isNewToMe
                            },
                            domProps: { textContent: _vm._s(msg.text) }
                          })
                        : _vm._e(),
                      msg.video && msg.video.vidStatusId == 200
                        ? _c(
                            "v-card",
                            [
                              _c("video-player", {
                                attrs: {
                                  "poster-url": msg.video.vidThumbUriComputed,
                                  "video-url": msg.video.vidUri
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      msg.video && msg.video.vidStatusId != 200
                        ? [
                            _c(
                              "p",
                              {
                                class: {
                                  "font-weight-bold accent--text": msg.isNewToMe
                                }
                              },
                              [_vm._v("Processing Video")]
                            ),
                            _c("v-progress-linear", {
                              attrs: {
                                color: "accent darken-1",
                                indeterminate: true
                              }
                            })
                          ]
                        : _vm._e(),
                      _c("div", { staticClass: "grey--text lighten-1" }, [
                        _vm._v(
                          _vm._s(_vm._f("moment")(msg.creationDate, "calendar"))
                        )
                      ])
                    ],
                    2
                  )
                ]
              ),
              index + 1 < _vm.pagedMessages.items.length
                ? _c("v-divider", {
                    key: index,
                    staticClass: "t-msg-divider grey darken-3"
                  })
                : _vm._e()
            ]
          }),
          _vm.videoMessageData.uploadingVideoMessage
            ? _c("v-divider", {
                staticClass: "t-msg-divider",
                attrs: { dark: "" }
              })
            : _vm._e(),
          _vm.videoMessageData.uploadingVideoMessage
            ? _c(
                "div",
                { staticClass: "t-msg-item me" },
                [
                  _c("h3", { attrs: { xclass: "display-1" } }, [_vm._v("Me")]),
                  [
                    _c("p", { staticClass: "font-weight-bold accent--text" }, [
                      _vm._v("Uploading...")
                    ]),
                    _c("v-progress-linear", {
                      attrs: {
                        color: "accent",
                        value: _vm.videoMessageData.videoUploadProgress
                      }
                    })
                  ]
                ],
                2
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "t-msg-send elevation-7" },
        [
          _c(
            "v-textarea",
            {
              attrs: {
                color: "primary",
                box: "",
                "hide-details": "",
                rows: "3",
                label: "Type a message",
                xdark: "",
                "auto-grow": ""
              },
              model: {
                value: _vm.newTextMessage,
                callback: function($$v) {
                  _vm.newTextMessage = $$v
                },
                expression: "newTextMessage"
              }
            },
            [
              _c(
                "span",
                { attrs: { slot: "append" }, slot: "append" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", icon: "", color: "primary" },
                      on: { click: _vm.sendMessage }
                    },
                    [_c("v-icon", [_vm._v("send")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "append-outer" }, slot: "append-outer" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "primary",
                        disabled: _vm.videoMessageData.uploadingVideoMessage
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.videoInput.requestFile()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("video_call")])],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("hidden-file-input", {
        ref: "videoInput",
        attrs: { accept: "video/*" },
        on: {
          fileSelected: _vm.sendVideoMessage,
          fileSelectCancelled: _vm.videoSelectCancelled
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }