var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "m-page",
    {
      attrs: {
        "xis-parent-child": "",
        "parent-class": _vm.dynamicGradiantBackground
      }
    },
    [
      _c(
        "m-toolbar",
        {
          staticClass: "transparent",
          attrs: {
            slot: "toolbar",
            "menu-button": "",
            dark: "",
            "text-color": "white--text",
            title: "Job Posts"
          },
          slot: "toolbar"
        },
        [
          false
            ? _c(
                "template",
                { slot: "toolbarButtons" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        round: "",
                        color: "primary ",
                        icon: !_vm.$vuetify.breakpoint.smAndUp,
                        to: { name: "Orgs_JobPosts__NewJobPost" }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("add")]),
                      _vm.$vuetify.breakpoint.smAndUp
                        ? _c("span", [_vm._v("POST A JOB")])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "dynamic-split-view",
        { attrs: { "body-color": "transparent", "extra-open": false } },
        [
          _vm.org
            ? _c(
                "div",
                {
                  staticClass: "mx-2",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [
                  _vm._l(_vm.jobPostStatusList, function(js) {
                    return _c(
                      "v-btn",
                      {
                        key: js.value,
                        class:
                          _vm.selectedJobPostStatusValue == js.value
                            ? "accent-glow accent-gradiant primary--text"
                            : "accent--text text--darken-1",
                        attrs: {
                          xlarge: "",
                          round: "",
                          xsmall: "!$vuetify.breakpoint.smAndUp",
                          flat: ""
                        },
                        on: {
                          click: function($event) {
                            _vm.selectedJobPostStatusValue = js.value
                          }
                        }
                      },
                      [_vm._v(_vm._s(js.text))]
                    )
                  }),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "z-index": "4" },
                      attrs: {
                        fabx: "",
                        large: _vm.$vuetify.breakpoint.smAndUp,
                        round: "",
                        color: "primary",
                        bottom: "",
                        right: "",
                        fixed: "",
                        to: { name: "Orgs_JobPosts__NewJobPost" }
                      }
                    },
                    [
                      _vm._v("\n        POST A JOB\n        "),
                      _c("v-icon", { attrs: { right: "" } }, [_vm._v("add")])
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-container",
            { staticClass: "pt-0", attrs: { fluid: "", "grid-list-lg": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-slide-y-transition",
                    [
                      !_vm.initializing && _vm.orgs.length == 0
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "elevation-4 xblue-grey darken-2 xwhite--text"
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { attrs: { "primary-title": "" } },
                                    [
                                      _c("div", [
                                        _c("div", { staticClass: "headline" }, [
                                          _vm._v("Register your business")
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            "And start posting jobs, and find the right fit (reword)"
                                          )
                                        ])
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            dark: "",
                                            block: "",
                                            round: "",
                                            large: "",
                                            to: "/organizations/regitser"
                                          }
                                        },
                                        [_vm._v("Register")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.initializing
                    ? _c(
                        "v-flex",
                        {
                          attrs: { xs12: "", "text-xs-center": "", "ma-5": "" }
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              large: "",
                              indeterminate: "",
                              color: "primary"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.initializing
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("job-cards-list", {
                            attrs: {
                              "job-posts": _vm.jobPostsExtended,
                              "meta-visible": true,
                              "save-visible": false,
                              "share-visible": true,
                              sm4: _vm.$vuetify.breakpoint.smAndUp
                            },
                            on: { "card-click": _vm.openJobPost },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "main-action",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "xoffset-y": "",
                                            top: "",
                                            left: "",
                                            color: "primary",
                                            "content-class": "reds"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                slot: "activator",
                                                flat: "",
                                                icon: "",
                                                color: "primary"
                                              },
                                              slot: "activator"
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("more_vert")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            [
                                              props.jobPost.isExpired
                                                ? _c(
                                                    "v-list-tile",
                                                    {
                                                      attrs: { ripple: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.startExtendAndPublish(
                                                            props.jobPost
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-tile-action",
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "visibility_off"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-tile-content",
                                                        [
                                                          _c(
                                                            "v-list-tile-title",
                                                            [
                                                              _vm._v(
                                                                "Extend & Publish"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : !props.jobPost.isListed
                                                ? _c(
                                                    "v-list-tile",
                                                    {
                                                      attrs: { ripple: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.startExtendAndPublish(
                                                            props.jobPost
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-tile-action",
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("visibility")
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-tile-content",
                                                        [
                                                          _c(
                                                            "v-list-tile-title",
                                                            [_vm._v("Publish")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-list-tile",
                                                    {
                                                      attrs: { ripple: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.unlist(
                                                            props.jobPost
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-tile-action",
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "visibility_off"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-tile-content",
                                                        [
                                                          _c(
                                                            "v-list-tile-title",
                                                            [_vm._v("Unlist")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                              _c("v-divider"),
                                              _c(
                                                "v-list-tile",
                                                {
                                                  attrs: {
                                                    ripple: "",
                                                    color: "error"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteJobPost(
                                                        props.jobPost
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-tile-action",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "error"
                                                          }
                                                        },
                                                        [_vm._v("delete")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-tile-content",
                                                    [
                                                      _c("v-list-tile-title", [
                                                        _vm._v("Delete...")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1221881308
                            )
                          }),
                          !_vm.jobPostsExtended ||
                          _vm.jobPostsExtended.length == 0
                            ? _c(
                                "v-card",
                                { staticClass: "mt-3", attrs: { light: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-xs-center" },
                                    [_vm._v("No job posts here")]
                                  ),
                                  false
                                    ? [
                                        _c("v-divider"),
                                        _c(
                                          "v-card-text",
                                          { attrs: { "primary-title": "" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "headline primary--text text-xs-center"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  POST A NEW OPENING\n                  "
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      round: "",
                                                      color: "primary",
                                                      dark: "",
                                                      to: "newjobpost"
                                                    }
                                                  },
                                                  [_vm._v("NEW JOB POST")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { xxwidth: "500" },
                  model: {
                    value: _vm.extendOp.dialog,
                    callback: function($$v) {
                      _vm.$set(_vm.extendOp, "dialog", $$v)
                    },
                    expression: "extendOp.dialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "headline",
                          attrs: { "primary-title": "" }
                        },
                        [_vm._v(_vm._s(_vm.extendOp.title))]
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { mandatory: true },
                              model: {
                                value: _vm.extendOp.jobLifeSpanDays,
                                callback: function($$v) {
                                  _vm.$set(_vm.extendOp, "jobLifeSpanDays", $$v)
                                },
                                expression: "extendOp.jobLifeSpanDays"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "3 Days",
                                  value: 3,
                                  color: "primary"
                                }
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "1 Week",
                                  value: 7,
                                  color: "primary"
                                }
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "2 Week",
                                  value: 14,
                                  color: "primary"
                                }
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "3 Week",
                                  value: 21,
                                  color: "primary"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "default", flat: "", round: "" },
                              on: {
                                click: function($event) {
                                  _vm.extendOp.jobLifeSpanDays = null
                                  _vm.extendOp.dialog = false
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                flat: "",
                                disabled: !_vm.extendOp.jobLifeSpanDays,
                                round: ""
                              },
                              on: { click: _vm.extendAndPublish }
                            },
                            [_vm._v("PUBLISH")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }