




















































































































































import Vue from "vue";
import * as models from "@/models";
import { G, rules } from "@/globals";
import jobCard from "@/components/jobs/jobCard.vue";
import { jobs } from "@/code/api.jobs.orgs";
import { accounts } from "@/code/api.accounts";
import { organizations } from "@/code/api.organizations";
import countryCitySelector from "@/components/country-city-selector.vue";
import { lookups } from "@/code/api.lookups";

declare type viewMode = "create" | "edit";

export default {
  name: "Orgs_NewJobPost",
  components: {
    jobCard: jobCard,
    countryCitySelector: countryCitySelector
  },
  data: () => ({
    createJob: {
      currentStep: 1,
      formValid: false,
      job: null as {
        org: models.org;
        city: models.city;
        industry: models.industry;
        title: string;
        description: string;
        isListed: boolean;
        jobLifeSpanDays: number;
      }
    },
    industries: null as models.industry[],
    currentView: <viewMode>null,
    isSaving: false,
    rules: {
      required: rules.required,
      max100: rules.maxCounter(100),
      max280: rules.maxCounter(280),
      max: rules.maxCounter
    }
  }),
  computed: {
    previewJobPost: function(): models.jobPostExtended {
      let j = this.createJob.job;
      return new models.jobPostExtended({
        id: 0,
        organization: j.org,
        industry: j.industry,
        city: j.city,
        title: j.title,
        description: j.description,
        isListed: j.isListed,
        creationDate: new Date().toJSON(),
        listingDate: new Date().toJSON(),
        expiryDate: new Date().toJSON(),
        context: {}
      });
    }
  },
  created: function() {
    G.data.customTitle = "New Job Post";
  },
  mounted: async function() {
    // if (this.$route.params.id && !isNaN(parseInt(this.$route.params.id))) {
    //   // view OR edit
    //   let jpId = parseInt(this.$route.params.id);
    // } else {

    await this.setViewMode("create");
    // }
    G.hideLoading();
  },
  methods: {
    setViewMode: async function(mode: viewMode) {
      switch (mode) {
        case "create":
          G.showLoading();
          let jobPost = {
            title: "",
            description: "",
            isListed: true,
            jobLifeSpanDays: 7,
            org: null,
            city: null,
            industry: null
            // organization: { id: accounts.currentOrgId },
            // city: null
          };
          // get current org
          let orgs = await organizations.getMyOrganizations();
          //Assign it to new job
          jobPost.org = orgs.find(o => o.id == accounts.currentOrgId);
          jobPost.city = jobPost.org.city;

          // Get industries for lookup
          let inds = await lookups.industries(jobPost.org.industry.id);
          this.industries = inds;

          this.createJob.job = jobPost;

          break;
        case "edit":
          break;
      }
      this.currentView = mode;
    },
    submitNewJobPost: function() {
      this.isSaving = true;
      let j = this.createJob.job;
      jobs.orgs.posts
        .create({
          orgId: j.org.id,
          cityId: j.city.id,
          industryId: j.industry.id,
          title: j.title,
          description: j.description,
          lifeSpan: j.jobLifeSpanDays,
          isListed: j.isListed
        })
        .then(jp => {
          console.log(jp);
          this.$router.push({
            name: "Orgs_JobPosts",
            // query: jp.id.toString(),
            params: <any>{ passedJobPost: jp, id: jp.id }
          });
        })
        .catch(err => {
          G.log.error("Org.JobPostForm.submitNewJobPost api create", err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    }
  }
};
