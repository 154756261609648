import { render, staticRenderFns } from "./NewJobPost.vue?vue&type=template&id=139c95ee&"
import script from "./NewJobPost.vue?vue&type=script&lang=ts&"
export * from "./NewJobPost.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!D:/Workspaces/Evidence/main/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCombobox } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VForm } from 'vuetify/lib'
import { VRadio } from 'vuetify/lib'
import { VRadioGroup } from 'vuetify/lib'
import { VStepper } from 'vuetify/lib'
import { VStepperContent } from 'vuetify/lib'
import { VStepperStep } from 'vuetify/lib'
import { VSwitch } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {VBtn,VCombobox,VDivider,VForm,VRadio,VRadioGroup,VStepper,VStepperContent,VStepperStep,VSwitch,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Workspaces\\Evidence\\main\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('139c95ee', component.options)
    } else {
      api.reload('139c95ee', component.options)
    }
    module.hot.accept("./NewJobPost.vue?vue&type=template&id=139c95ee&", function () {
      api.rerender('139c95ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/orgs/NewJobPost.vue"
export default component.exports