var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "m-page",
    [
      _vm.$vuetify.breakpoint.smAndUp
        ? _c("m-toolbar", {
            staticClass: "transparent",
            attrs: {
              slot: "toolbar",
              "back-button": "",
              "menu-button": false,
              title: _vm.pageTitle
            },
            slot: "toolbar"
          })
        : _vm._e(),
      !_vm.initialLoadCompleted
        ? [
            _c("div", { staticClass: "sq text-xs-center" }, [
              _c(
                "span",
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ])
          ]
        : _vm._e(),
      _c(
        "v-container",
        {
          class: [{ "pa-0": !_vm.$vuetify.breakpoint.smAndUp }],
          attrs: { "grid-list-md": _vm.$vuetify.breakpoint.smAndUp, fluid: "" }
        },
        [
          _vm.initialLoadCompleted
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm4: "" } },
                    [
                      _c(
                        "video-player",
                        {
                          attrs: {
                            "poster-url": _vm.vidThumbUriComputed,
                            "video-url": _vm.vidUri
                          }
                        },
                        [
                          !_vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "div",
                                {
                                  staticClass: "white--text",
                                  staticStyle: {
                                    display: "flex",
                                    opacity: ".8"
                                  },
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _c(
                                    "v-toolbar-side-icon",
                                    {
                                      attrs: { dark: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.$router.go(-1)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "text-shadow":
                                              "0 0 4px rgba(0, 0, 0, 0.7)"
                                          }
                                        },
                                        [_vm._v("chevron_left")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-toolbar-title",
                                    {
                                      staticStyle: {
                                        display: "inline-flex",
                                        margin: "9px 0px -1px"
                                      }
                                    },
                                    [
                                      _vm.profile
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "text-shadow":
                                                  "0 0 4px rgba(0, 0, 0, 0.7)",
                                                overflow: "hidden",
                                                "text-overflow": "ellipsis"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.profile.fullname)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { staticClass: "youtube-bar", attrs: { wrap: "" } },
                            [
                              false
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs2: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pa-0",
                                          attrs: {
                                            small: "",
                                            depressed: "",
                                            dark: "",
                                            color: "error"
                                          },
                                          on: { click: _vm.ignore }
                                        },
                                        [
                                          _c("span", [_vm._v("Ignore")]),
                                          _c("v-icon", [_vm._v("cancel")])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        small: "",
                                        depressed: "",
                                        dark: "",
                                        color: "info"
                                      },
                                      on: { click: _vm.messageSeeker }
                                    },
                                    [
                                      _c("span", [_vm._v("Contact")]),
                                      _c("v-icon", [_vm._v("message")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        small: "",
                                        depressed: "",
                                        dark: "",
                                        color: "success darken-1"
                                      },
                                      on: { click: _vm.shortlist }
                                    },
                                    [
                                      _c("span", [_vm._v("Shortlist")]),
                                      _c("v-icon", [_vm._v("check_circle")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm8: "" } },
                    [
                      _vm.profile
                        ? [
                            _c("v-slide-x-reverse-transition", [
                              _vm.threadVisible
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "accent-glow seeker-floating-thread"
                                    },
                                    [
                                      _c("thread-view", {
                                        attrs: {
                                          "passed-thread": _vm.seekerThread
                                        }
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            color: "primary",
                                            top: "",
                                            right: "",
                                            absolute: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.threadVisible = false
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("close")])],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c(
                              "m-tabs",
                              {
                                class: {
                                  "mx-2 my-1": !_vm.$vuetify.breakpoint.smAndUp
                                },
                                attrs: { tabs: ["About", "History"] },
                                model: {
                                  value: _vm.currentTab,
                                  callback: function($$v) {
                                    _vm.currentTab = $$v
                                  },
                                  expression: "currentTab"
                                }
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "afterSpacer" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "accent--text text--darken-1",
                                        attrs: {
                                          round: "",
                                          flat: "",
                                          loading: _vm.seekerThread == null
                                        },
                                        on: { click: _vm.messageSeeker }
                                      },
                                      [
                                        _c(
                                          "v-badge",
                                          {
                                            attrs: { color: "red" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "badge",
                                                  fn: function() {
                                                    return [
                                                      _vm.seekerThread &&
                                                      _vm.seekerThread
                                                        .newMessagesCount > 0
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.seekerThread
                                                                  .newMessagesCount
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              1247866462
                                            )
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.messageButtonText) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            ),
                            _c(
                              "v-window",
                              {
                                attrs: { xvertical: "" },
                                model: {
                                  value: _vm.currentTab,
                                  callback: function($$v) {
                                    _vm.currentTab = $$v
                                  },
                                  expression: "currentTab"
                                }
                              },
                              [
                                _c(
                                  "v-window-item",
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "transparent"
                                        }
                                      },
                                      [
                                        _c("v-card-text", [
                                          _c("h2", [
                                            _vm._v(_vm._s(_vm.profile.headline))
                                          ]),
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "accent--text text--darken-3"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.profile.industry.title
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "h3",
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.profile.firstname
                                                  ) +
                                                  "\n                    " +
                                                  _vm._s(_vm.profile.lastname) +
                                                  "\n                    "
                                              ),
                                              _vm.profile.city
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "subheading"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "— " +
                                                          _vm._s(
                                                            _vm.profile.city
                                                              .name
                                                          )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("br"),
                                              _vm._l(
                                                _vm.profile.jobTypes,
                                                function(jt, idx) {
                                                  return _c(
                                                    "small",
                                                    { key: jt },
                                                    [
                                                      _c("span", [
                                                        _vm._v(_vm._s(jt))
                                                      ]),
                                                      idx + 1 <
                                                      _vm.profile.jobTypes
                                                        .length
                                                        ? _c("span", {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                " | "
                                                              )
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  )
                                                }
                                              ),
                                              _c("br"),
                                              _vm._l(
                                                _vm.profile.languages,
                                                function(lang, idx) {
                                                  return _c(
                                                    "small",
                                                    {
                                                      key: lang.name,
                                                      staticClass:
                                                        "accent--text text--darken-3"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(lang.name)
                                                        )
                                                      ]),
                                                      idx + 1 <
                                                      _vm.profile.languages
                                                        .length
                                                        ? _c("span", {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                " | "
                                                              )
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.profile.bio) +
                                                "\n                  "
                                            )
                                          ])
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-window-item",
                                  _vm._l(_vm.groupedSeekerLabelsEx, function(
                                    gSlx
                                  ) {
                                    return _c(
                                      "v-list",
                                      {
                                        key: gSlx.key.content,
                                        attrs: {
                                          "two-line": "",
                                          subheader: "",
                                          light: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-subheader",
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  label: "",
                                                  large: "",
                                                  dark: "",
                                                  color:
                                                    gSlx.key.contentAttributes
                                                      .color
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-avatar",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(
                                                          gSlx.key
                                                            .contentAttributes
                                                            .icon
                                                        )
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(gSlx.key.content) +
                                                    "\n                  "
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._l(gSlx.items, function(sl) {
                                          return _c(
                                            "v-list-tile",
                                            {
                                              key: sl.id,
                                              attrs: { xavatar: "" }
                                            },
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v(
                                                      _vm._s(sl.jobPost.title)
                                                    )
                                                  ]),
                                                  _c("v-list-tile-sub-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("moment")(
                                                          sl.creationDate,
                                                          "calendar"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            false
                              ? _c(
                                  "v-tabs",
                                  {
                                    attrs: {
                                      grow: "",
                                      xdark: "",
                                      color: "transparent"
                                    }
                                  },
                                  [
                                    _c("v-tabs-slider", {
                                      attrs: { color: "primary" }
                                    }),
                                    _c(
                                      "v-tab",
                                      {
                                        attrs: {
                                          href: "#tabInfo",
                                          disabled: _vm.G.isSeekerMode
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              About\n            "
                                        )
                                      ]
                                    ),
                                    !_vm.G.isSeekerMode
                                      ? _c(
                                          "v-tab",
                                          { attrs: { href: "#tabContext" } },
                                          [
                                            _c(
                                              "v-badge",
                                              { attrs: { color: "info" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: { slot: "badge" },
                                                    slot: "badge"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.seekerLabelsEx
                                                          ? _vm.seekerLabelsEx
                                                              .length
                                                          : 0
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                Activities\n              "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("v-tab-item", {
                                      attrs: { value: "tabInfo" }
                                    }),
                                    !_vm.G.isSeekerMode
                                      ? _c("v-tab-item", {
                                          attrs: { value: "tabContext" }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("job-selector-dialog", { ref: "jobSelectorDialog" }, [
        _c("span", { attrs: { slot: "header" }, slot: "header" }, [
          _vm._v("Select Job")
        ])
      ]),
      _c("new-thread-dialog", { ref: "newThreadDialog" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }