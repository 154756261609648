



































































































































































































































import Vue from "vue";
import * as models from "@/models";
import { G } from "@/globals";
import { accounts } from "@/code/api.accounts";
import { jobs } from "@/code/api.jobs.orgs";
import db from "@/code/localDb";
import jobCardsList from "@/components/jobs/jobCardsList.vue";
import moment from "moment";

// import * as confirm from "@/components/confirm/confirm";
// let cd: confirm.confirmDialogWrapper;

export default {
  name: "Orgs_JobPosts",
  // props: ["passedOrgId"],
  components: {
    jobCardsList: jobCardsList
  },
  data: () => ({
    org: null as models.org,
    initializing: true,
    //jobPosts: <models.jobPost[]>[],
    jobPostsExtended: null as models.jobPostExtended[],
    selectedJobPostStatusValue: models.jobPostStatusString.published,
    extendOp: {
      dialog: false,
      title: "Extend & Publish",
      jobLifeSpanDays: null,
      targetJobPost: <models.jobPostExtended>null
    },
    orgMenuVisible: false
  }),
  created: function() {
    // cd = confirm.getConfirmDialog(Vue);
    // xG.data.customTitle = "My Business";
  },

  mounted: async function() {
    let cl = G.data.currentLogin;

    // this.initializing = false;
    this.org = accounts.currentOrg;
    if (this.org) await this.loadJobPosts();
  },
  computed: {
    jobPostStatusList: function() {
      return models.jobPostStatusList;
    },
    orgs: function(): models.org[] {
      return G.data.currentLogin.orgs;
    },
    currentOrgId: {
      get: function(): number {
        return accounts.currentOrgId;
      },
      set: function(newValue: number) {
        console.log("setting currentOrgId");
        accounts.currentOrgId = newValue;
      }
    }
  },
  methods: {
    loadJobPosts: function() {
      G.showLoading();
      this.jobPostsExtended = null;
      return jobs.orgs.posts
        .getByOrgId(this.org.id, true, this.selectedJobPostStatusValue)
        .then(jps => {
          // this.jobPosts = jps;
          this.jobPostsExtended = jps;
          // this.jobPostsExtended = jps.map(jp => new models.jobPostExtended(jp));
        })
        .catch(err => {
          G.log.error("Orgs.JobPosts.getByOrgId", err);
        })
        .finally(() => {
          this.initializing = false;
          G.hideLoading();
        });
    },

    deleteJobPost: function(jobPost: models.jobPostExtended) {
      G.confirmDialog
        .open(
          `Are you sure you want to delete (${jobPost.title}) job post?`,
          "Delete",
          "Oh no",
          `Yes, DELETE`, // (${jobPost.title})
          "default",
          "error"
        )
        .then(confirm => {
          if (confirm) {
            jobs.orgs.posts
              .delete(jobPost.id)
              .then(() => {
                console.log("deleted");
                G.showSnackbar(
                  `(${jobPost.title}) job post has been deleted.`,
                  "success"
                );

                let i = this.jobPostsExtended.findIndex(
                  j => j.id == jobPost.id
                );
                this.jobPostsExtended.splice(i, 1);
              })
              .catch(err => {
                G.log.error("Org.Home.deleteJobPost", err);
              });
          }
        });
    },
    openJobPost: function(jpx: models.jobPostExtended) {
      this.$router.push({
        name: "Orgs_JobPosts__JobPost",
        query: {
          id: jpx.id.toString()
        },
        params: <any>{
          passedJobPost: jpx
        }
      });
    },
    startExtendAndPublish: function(jpx: models.jobPostExtended) {
      this.extendOp.targetJobPost = jpx;
      this.extendOp.dialog = true;
    },
    extendAndPublish: function(jpx: models.jobPostExtended) {
      this.extendOp.dialog = false;
      G.showLoading();
      jobs.orgs.posts
        .extendAndPublish(
          this.extendOp.targetJobPost.id,
          this.extendOp.jobLifeSpanDays
        )
        .then(jpx => {
          console.log("extended");
          G.showSnackbar(
            `(${jpx.title}) job post has been extended till 
              <strong>${jpx.expiryDateMoment.calendar()}</strong>
               and published.`,
            "success"
          );
          //Remove
          let i = this.jobPostsExtended.findIndex(j => j.id == jpx.id);
          this.jobPostsExtended.splice(i, 1);
          //Add
          this.jobPostsExtended.push(jpx);
          //Sort
          this.jobPostsExtended = models.jobPostExtended.sort(
            this.jobPostsExtended
          );
          // //                     let ffx: string;
          // // ff.
          // //                     ff.
          //  this.jobPostsExtended.
        })
        .catch(err => {
          G.log.error("Org.Home.extendAndPublish", err);
        })
        .finally(() => {
          G.hideLoading();
        });
    },
    unlist: function(jp: models.jobPostExtended) {
      G.confirmDialog
        .open(
          `Unlisting a job post will hide it from the public. 
          Are you sure you want to unlist (${jp.title}) job post?`,
          /*"DELETE",*/
          "Unlist",
          "No",
          `Yes, UNLIST`, // (${jobPost.title})
          "default",
          "error"
        )
        .then(confirm => {
          if (confirm) {
            this.extendOp.dialog = false;
            G.showLoading();

            jobs.orgs.posts
              .unlist(jp.id)
              .then(jpx => {
                console.log("unlisted");
                G.showSnackbar(
                  `(${jp.title}) job post is now unlisted.`,
                  "success"
                );
                Object.assign(jp, jpx);
                // //Remove
                // let i = this.jobPostsExtended.findIndex(j => j.id == jpx.id);
                // this.jobPostsExtended.splice(i, 1);
                // //Add
                // this.jobPostsExtended.push(jpx);
                // //Sort
                // this.jobPostsExtended = models.jobPostExtended.sort(
                //   this.jobPostsExtended
                // );
                // // //                     let ffx: string;
                // // // ff.
                // // //                     ff.
                // //  this.jobPostsExtended.
              })
              .catch(err => {
                G.log.error("Org.Home.unlist", err);
              })
              .finally(() => {
                G.hideLoading();
              });
          }
        });
    }
  },
  watch: {
    selectedJobPostStatusValue: function(newValue) {
      this.initializing = true;
      this.loadJobPosts();
    }
    // orgs: function(newOrgs) {
    //   if (this.orgs.length == 0) return;
    //   if (this.currentOrgId) {
    //     console.log(" Found currentOrgId");
    //     this.org = this.orgs.find(o => o.id == this.currentOrgId);
    //   } else {
    //     console.log("No Found currentOrgId");
    //     console.log(this.orgs.length);
    //     if (this.orgs.length == 1) {
    //       this.currentOrgId = this.orgs[0].id;
    //       this.org = this.orgs.find(o => o.id == this.orgs[0].id);
    //     }
    //   }
    //   if (!this.org) this.$refs.orgSelector.show();
    //   // if (!this.org) (<any>this.$refs.orgSelector).show();
    // }
  }
};
