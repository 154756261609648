var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "m-page",
    [
      _c("m-toolbar", {
        staticClass: "transparent",
        attrs: {
          slot: "toolbar",
          "back-button": "",
          "menu-button": false,
          title: "New Job Post"
        },
        slot: "toolbar"
      }),
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "fill-height",
          staticStyle: { height: "auto" },
          model: {
            value: _vm.createJob.formValid,
            callback: function($$v) {
              _vm.$set(_vm.createJob, "formValid", $$v)
            },
            expression: "createJob.formValid"
          }
        },
        [
          _vm.currentView == "create" && _vm.createJob.job
            ? _c(
                "v-stepper",
                {
                  staticClass: "fill-height",
                  staticStyle: { "background-color": "transparent" },
                  attrs: { vertical: "" },
                  model: {
                    value: _vm.createJob.currentStep,
                    callback: function($$v) {
                      _vm.$set(_vm.createJob, "currentStep", $$v)
                    },
                    expression: "createJob.currentStep"
                  }
                },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        rules: [
                          function() {
                            return (
                              _vm.createJob.currentStep <= 1 ||
                              _vm.rules.required(_vm.createJob.job.title)
                            )
                          },
                          function() {
                            return (
                              _vm.createJob.currentStep <= 1 ||
                              (!!_vm.createJob.job.industry &&
                                !!_vm.createJob.job.industry.id)
                            )
                          }
                        ],
                        complete: _vm.createJob.currentStep > 1,
                        step: "1"
                      }
                    },
                    [_vm._v("Headline")]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          "xxxvalidate-on-blur": "",
                          box: "",
                          items: _vm.industries,
                          "item-value": "id",
                          "item-text": "title",
                          label: "Sector",
                          required: true,
                          "return-object": true,
                          rules: [
                            function(v) {
                              return (!!v && !!v.id) || "Required"
                            }
                          ],
                          loading: !_vm.industries
                        },
                        model: {
                          value: _vm.createJob.job.industry,
                          callback: function($$v) {
                            _vm.$set(_vm.createJob.job, "industry", $$v)
                          },
                          expression: "createJob.job.industry"
                        }
                      }),
                      _c("v-divider"),
                      _c("span", [
                        _vm._v(
                          "To the point, what would the business card say?"
                        )
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          name: "name",
                          box: "",
                          label: "Headline",
                          counter: 100,
                          rules: [_vm.rules.required, _vm.rules.max100],
                          maxlength: "100",
                          large: ""
                        },
                        model: {
                          value: _vm.createJob.job.title,
                          callback: function($$v) {
                            _vm.$set(_vm.createJob.job, "title", $$v)
                          },
                          expression: "createJob.job.title"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { round: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.createJob.currentStep++
                                }
                              }
                            },
                            [_vm._v("Next")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        rules: [
                          function() {
                            return (
                              _vm.createJob.currentStep <= 2 ||
                              _vm.rules.required(_vm.createJob.job.description)
                            )
                          }
                        ],
                        complete: _vm.createJob.currentStep > 2,
                        step: "2"
                      }
                    },
                    [_vm._v("Description")]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c("span", [
                        _vm._v(
                          "Cut the fat, you only have 280 characters. What are you really lookin for?"
                        )
                      ]),
                      _c("v-textarea", {
                        attrs: {
                          "auto-grow": "",
                          box: "",
                          label: "description",
                          rows: "1",
                          counter: 280,
                          rules: [_vm.rules.required, _vm.rules.max280],
                          maxlength: "280"
                        },
                        model: {
                          value: _vm.createJob.job.description,
                          callback: function($$v) {
                            _vm.$set(_vm.createJob.job, "description", $$v)
                          },
                          expression: "createJob.job.description"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { round: "", flat: "", color: "default" },
                              on: {
                                click: function($event) {
                                  _vm.createJob.currentStep--
                                }
                              }
                            },
                            [_vm._v("Back")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { round: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.createJob.currentStep++
                                }
                              }
                            },
                            [_vm._v("Next")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        rules: [
                          function() {
                            return (
                              _vm.createJob.currentStep <= 3 ||
                              (!!_vm.createJob.job.city || "City missing")
                            )
                          }
                        ],
                        complete: _vm.createJob.currentStep > 3,
                        step: "3"
                      }
                    },
                    [_vm._v("Location")]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c("country-city-selector", {
                        attrs: { required: "" },
                        model: {
                          value: _vm.createJob.job.city,
                          callback: function($$v) {
                            _vm.$set(_vm.createJob.job, "city", $$v)
                          },
                          expression: "createJob.job.city"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { round: "", flat: "", color: "default" },
                              on: {
                                click: function($event) {
                                  _vm.createJob.currentStep--
                                }
                              }
                            },
                            [_vm._v("Back")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { round: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.createJob.currentStep++
                                }
                              }
                            },
                            [_vm._v("Next")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.createJob.currentStep > 4,
                        step: "4"
                      }
                    },
                    [_vm._v("Life Span")]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "4" } },
                    [
                      _c("span", [
                        _vm._v(
                          "Flor how long would you like to keep this position open?"
                        )
                      ]),
                      _c(
                        "v-radio-group",
                        {
                          attrs: { mandatory: true, color: "accent" },
                          model: {
                            value: _vm.createJob.job.jobLifeSpanDays,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.createJob.job,
                                "jobLifeSpanDays",
                                $$v
                              )
                            },
                            expression: "createJob.job.jobLifeSpanDays"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: "3 Days",
                              value: 3,
                              xcolor: "primary"
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: "1 Week",
                              value: 7,
                              xcolor: "primary"
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: "2 Week",
                              value: 14,
                              xcolor: "primary"
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: "3 Week",
                              value: 21,
                              xcolor: "primary"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { round: "", flat: "", color: "default" },
                              on: {
                                click: function($event) {
                                  _vm.createJob.currentStep--
                                }
                              }
                            },
                            [_vm._v("Back")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { round: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.createJob.currentStep++
                                }
                              }
                            },
                            [_vm._v("Next")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.createJob.currentStep > 5,
                        step: "5"
                      }
                    },
                    [_vm._v("Visibility")]
                  ),
                  _c("v-stepper-content", { attrs: { step: "5" } }, [
                    _c(
                      "div",
                      { staticClass: "px-3" },
                      [
                        _c("v-switch", {
                          attrs: {
                            color: "accent",
                            label: _vm.createJob.job.isListed
                              ? "Published"
                              : "Not Listed"
                          },
                          model: {
                            value: _vm.createJob.job.isListed,
                            callback: function($$v) {
                              _vm.$set(_vm.createJob.job, "isListed", $$v)
                            },
                            expression: "createJob.job.isListed"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { round: "", flat: "", color: "default" },
                            on: {
                              click: function($event) {
                                _vm.createJob.currentStep--
                              }
                            }
                          },
                          [_vm._v("Back")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { round: "", color: "primary" },
                            on: {
                              click: function($event) {
                                _vm.createJob.currentStep++
                              }
                            }
                          },
                          [_vm._v("Next")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.createJob.formValid, step: "6" } },
                    [_vm._v("Preview & Publish")]
                  ),
                  _c("v-stepper-content", { attrs: { step: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "pa-3" },
                      [
                        _c(
                          "job-card",
                          { attrs: { "job-post": _vm.previewJobPost } },
                          [
                            _c("span", {
                              attrs: { slot: "card-actions" },
                              slot: "card-actions"
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { round: "", flat: "", color: "default" },
                            on: {
                              click: function($event) {
                                _vm.createJob.currentStep--
                              }
                            }
                          },
                          [_vm._v("Back")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              round: "",
                              disabled:
                                !_vm.createJob.formValid || _vm.isSaving,
                              loading: _vm.isSaving,
                              color: _vm.createJob.job.isListed
                                ? "success"
                                : "primary"
                            },
                            on: { click: _vm.submitNewJobPost }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.createJob.job.isListed ? "Post" : "Save"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }