var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "m-page",
    {
      attrs: {
        "xis-parent-child": "",
        "parent-class": _vm.dynamicGradiantBackground
      }
    },
    [
      _c(
        "m-toolbar",
        {
          staticClass: "transparent",
          attrs: {
            slot: "toolbar",
            "menu-button": false,
            dark: "",
            "back-button": "",
            "text-color": "white--text",
            title: _vm.pageTitle
          },
          slot: "toolbar"
        },
        [
          false
            ? _c(
                "template",
                { slot: "toolbarButtons" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        round: "",
                        color: "primary ",
                        icon: !_vm.$vuetify.breakpoint.smAndUp,
                        to: { name: "Orgs_JobPosts__NewJobPost" }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("add")]),
                      _vm.$vuetify.breakpoint.smAndUp
                        ? _c("span", [_vm._v("POST A JOB")])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "dynamic-split-view",
        { attrs: { "body-color": "transparent", "extra-open": false } },
        [
          _c("m-tabs", {
            staticClass: "mx-2",
            attrs: { slot: "header", tabs: ["Candidates", "Info"] },
            slot: "header",
            model: {
              value: _vm.currentTab,
              callback: function($$v) {
                _vm.currentTab = $$v
              },
              expression: "currentTab"
            }
          }),
          _c(
            "v-window",
            {
              attrs: { xvertical: "" },
              model: {
                value: _vm.currentTab,
                callback: function($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab"
              }
            },
            [
              _c(
                "v-window-item",
                [
                  _c("m-tabs", {
                    attrs: { tabs: _vm.predefinedLabels },
                    model: {
                      value: _vm.currentLabelIndex,
                      callback: function($$v) {
                        _vm.currentLabelIndex = $$v
                      },
                      expression: "currentLabelIndex"
                    }
                  }),
                  _c(
                    "v-container",
                    {
                      attrs: {
                        fluid: "",
                        "fill-height": "",
                        "grid-list-md": ""
                      }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            wrap: "",
                            "xjustify-center": "",
                            "xalign-center": ""
                          }
                        },
                        [
                          !!_vm.seekers && _vm.seekers.length > 0
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "seeker-cards-list",
                                    {
                                      attrs: {
                                        small: false,
                                        "no-video": "",
                                        "seeker-profiles": _vm.seekers
                                      },
                                      on: { "card-click": _vm.seekerCardClick }
                                    },
                                    [
                                      _c("span", {
                                        attrs: { slot: "card-actions" },
                                        slot: "card-actions"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-center",
                                  attrs: { xs12: "" }
                                },
                                [_vm._v("- Empty -")]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-window-item",
                [
                  _c("job-card", {
                    attrs: {
                      "job-post": _vm.jobPost,
                      "save-visible": false,
                      "apply-visible": false,
                      "meta-visible": true,
                      "details-visible": false
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }