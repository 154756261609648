











































































































































































import Vue from "vue";
import * as models from "@/models";
import { G } from "@/globals";
import { jobs } from "@/code/api.jobs.seekers";
import { accounts } from "@/code/api.accounts";
import { lookups } from "@/code/api.lookups";
import seekerCardsList from "@/components/seekers/seekerCardsList.vue";
// import jobCardsList from "@/components/jobs/jobCardsList.vue";

export default {
  name: "Orgs_Discover",
  components: { seekerCardsList },
  data: () => ({
    extraOpen: false,
    seekerProfiles: { items: [] } as models.paged<models.seekerProfile>,
    initializing: true,
    pageSize: 12,
    loadingMore: false,
    smallMode: accounts.miniSeekerCard,
    searchQuery: null as string,
    filter: {
      orgIndustry: { title: "..." } as models.industry,
      lastUsedIndustry: null,
      countries: null,
      cities: null,
      industries: null,
      sectors: null
    },

    loadingProfiles: true,
    filter_selectedCountries: [],
    filter_selectedCities: [],
    filter_selectedIndustries: [],
    filter_selectedSectors: []
  }),
  watch: {
    initializing: function(newValue: boolean) {
      newValue ? G.showLoading() : G.hideLoading();
    },
    smallMode: function(newValue: boolean) {
      accounts.miniSeekerCard = newValue;
    },
    filter_selectedCountries: function(cns: models.country[]) {
      this.filter_selectedCities = [];
      this.filter.cities = [];
      if (cns.length == 1) {
        lookups.cities(cns[0].code).then(cn => {
          this.filter.cities = cn.cities;
        });
      }
    },

    filter_selectedIndustries: function(inds: models.industry[]) {
      this.filter_selectedSectors = [];
      this.filter.sectors = [];
      if (inds.length == 1) {
        lookups.industries_Cached().then(sourceInds => {
          this.filter.sectors = sourceInds.filter(
            ind => ind.id == inds[0].id
          )[0].children;
        });
      }
    }
  },
  computed: {
    currentFilterTitle: function(): string {
      if (this.filter.lastUsedIndustry) {
        return this.filter.lastUsedIndustry.title;
      } else {
        return `All ${this.filter.orgIndustry.title} Sectors`;
      }
    }
  },
  created: function() {
    G.showLoading();
    //=======
    this.filter.orgIndustry = accounts.currentOrg.industry;
    // this.filter.lastUsedIndustry = this.filter.orgIndustry;
    lookups.industries_Cached().then(inds => {
      this.filter.sectors = inds.find(
        ind => ind.id == this.filter.orgIndustry.id
      ).children;
    });

    lookups.countries().then(cns => {
      this.filter.countries = cns;
    });
    lookups.industries_Cached().then(inds => {
      this.filter.industries = inds;
    });
  },
  activated: function() {
    G.data.customTitle = "Discover";
  },
  mounted: async function() {
    await this.loadProfiles(0, this.pageSize, this.filter.lastUsedIndustry);
    this.initializing = false;
  },
  methods: {
    loadProfiles: function(skip: number, take: number, ind?: models.industry) {
      if (skip == 0) this.loadingProfiles = true;

      if (!ind) ind = this.filter.orgIndustry;
      return jobs.seekers.profiles
        .getProfiles_Paged(ind.id, skip, take)
        .then(sps => {
          let combinedItems = [...this.seekerProfiles.items, ...sps.items];
          this.seekerProfiles = sps;
          this.seekerProfiles.items = combinedItems;

          this.seekerProfiles = sps;
        })
        .catch(err => {
          G.log.error("orgs.discover.loadProfiles", err);
        })
        .finally(() => {
          this.loadingProfiles = false;
          this.loadingMore = false;
          G.hideLoading();
          this.initializing = false;
        });
    },
    loadMore: function() {
      // if (!this.jobPostsExtended.hasNext) return;
      this.loadingMore = true;
      this.loadProfiles(
        this.seekerProfiles.items.length,
        this.pageSize,
        this.filter.lastUsedIndustry
      );
    },
    // Search
    // runBasicSearch: function() {
    //   this.extraOpen = false;
    //   this.seekerProfiles = { items: [] };
    //   this.basicSearchProfiles(0, this.pageSize);
    // },
    // basicSearchProfiles: function(skip: number, take: number) {
    //   if (skip == 0) this.loadingProfiles = true;
    //   return jobs.seekers.profiles
    //     .basicSearchProfiles_Paged(this.searchQuery, skip, take)
    //     .then(sps => {
    //       let combinedItems = [...this.seekerProfiles.items, ...sps.items];
    //       this.seekerProfiles = sps;
    //       this.seekerProfiles.items = combinedItems;

    //       this.seekerProfiles = sps;
    //     })
    //     .catch(err => {
    //       G.log.error("orgs.discover.loadProfiles", err);
    //     })
    //     .finally(() => {
    //       this.loadingProfiles = false;
    //       this.loadingMore = false;
    //       G.hideLoading();
    //       this.initializing = false;
    //     });
    // },
    // basicSearchMore: function() {
    //   // if (!this.jobPostsExtended.hasNext) return;
    //   this.loadingMore = true;
    //   this.basicSearchProfiles(this.seekerProfiles.items.length, this.pageSize);
    // },
    runSearch: function() {
      this.extraOpen = false;
      this.seekerProfiles = { items: [] };
      this.searchProfiles(0, this.pageSize);
    },
    searchProfiles: function(skip: number, take: number) {
      if (skip == 0) this.loadingProfiles = true;
      let extractIds = (source: [], key: string) => {
        if (!source || source.length == 0) return [];
        return source.map(itm => itm[key]);
      };
      return jobs.seekers.profiles
        .searchProfiles_Paged(
          this.searchQuery,
          skip,
          take,
          extractIds(this.filter_selectedIndustries, "id"),
          extractIds(this.filter_selectedSectors, "id"),
          extractIds(this.filter_selectedCountries, "code"),
          extractIds(this.filter_selectedCities, "id")
        )
        .then(sps => {
          let combinedItems = [...this.seekerProfiles.items, ...sps.items];
          this.seekerProfiles = sps;
          this.seekerProfiles.items = combinedItems;

          this.seekerProfiles = sps;
        })
        .catch(err => {
          G.log.error("orgs.discover.loadProfiles", err);
        })
        .finally(() => {
          this.loadingProfiles = false;
          this.loadingMore = false;
          G.hideLoading();
          this.initializing = false;
        });
    },
    searchMore: function() {
      // if (!this.jobPostsExtended.hasNext) return;
      this.loadingMore = true;
      this.searchProfiles(this.seekerProfiles.items.length, this.pageSize);
    },
    seekerCardClick: function(sp: models.seekerProfile) {
      this.$router.push({
        name: "Orgs_Discover__SeekerProfile",
        params: <any>{ passedProfile: sp, id: sp.id }
      });
    }
  }
};
