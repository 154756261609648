













































































































































































































































































































import Vue from "vue";
import * as models from "@/models";
import { G } from "@/globals";
import { jobs as jSeekers } from "@/code/api.jobs.seekers";
import { jobs as jOrgs } from "@/code/api.jobs.orgs";
let jobsApi = { ...jSeekers, ...jOrgs };
import videoPlayer from "@/components/videoPlayer.vue";
import jobSelectorDialog from "@/components/jobs/jobSelectorDialog.vue";
import newThreadDialog from "@/components/messaging/newThreadDialog.vue";
import { accounts } from "@/code/api.accounts";
import threadView from "@/components/messaging/messaging.Thread.View.vue";
import { messaging } from "@/code/api.messaging";

export default {
  name: "Orgs_SeekerProfile",
  components: { threadView, videoPlayer, jobSelectorDialog, newThreadDialog },
  props: ["passedProfile"],
  created: function() {
    G.data.customTitle = "...";
  },
  data: function() {
    return {
      threadVisible: false,
      currentTab: 0,
      currentTargetJobPost: null,
      initialLoadCompleted: false,
      seekerThread: null,
      // profile: <models.seekerProfile>(this.passedProfile || {
      //   id: null,
      //   headline: "Loading...",
      //   industry: { title: "..." },
      //   context: []
      // })
      profile: {
        id: null,
        headline: "Loading...",
        industry: { title: "..." },
        context: {
          labels: [],
          threads: []
        }
      } as models.seekerProfileExtended
    };
  },
  methods: {
    initialLoadProfile: function(): void {
      // G.log.action(
      //   "Seeker.Profile.initialLoadProfile",
      //   "Getting Profile",
      //   true
      // );
      // console.log(this.$route);

      let idToload: number;
      if (!!this.passedProfile && !!this.passedProfile.id) {
        // // A profile was passed:
        // if (this.profile.seekerVideos && this.profile.seekerVideos.length > 0) {
        //   // A full profile was passed (because we have videos):
        //   this.initialLoadCompleted = true;

        //   return;
        // } else {
        // A partial profile was passed, load the rest
        this.profile = new models.seekerProfileExtended(this.passedProfile);
        idToload = this.profile.id;
        // }
      } else {
        //No profile was passed:
        //exit if no id in url
        if (!this.$route.params.id) {
          this.$router.push({
            name: "Home"
          });
          return;
        } else {
          //we have an id
          idToload = parseInt(<string>this.$route.params.id);
        }
      }
      this.loadProfile(idToload);
    },
    loadProfile: function(id?: number) {
      if (!id) id = this.profile.id;
      jobsApi.seekers.profiles
        .getProfile(id, true)
        .then(profile => {
          G.log.action("Seeker.Profile.loadProfile", profile, true);
          this.initialLoadCompleted = true;
          this.profile = new models.seekerProfileExtended(profile);
          G.data.customTitle = this.profile.fullname;
          //Load Msgs
          messaging
            .getThreadBySeekerId(id, 0, 3, accounts.currentOrgId)
            .then(thrd => {
              console.log(thrd);

              this.seekerThread = thrd || {};
            });
        })
        .catch(err => {
          G.log.error("Seeker.Profile.loadProfile profiles.getProfile", err);
          this.$router.push({
            name: "Home"
          });
        })
        .finally(() => {});
    },
    shortlist: async function() {
      if (!this.currentTargetJobPost) {
        let jpxsPromise = jobsApi.orgs.posts.getByOrgId(
          accounts.currentOrgId,
          false,
          models.jobPostStatusString.published
        );
        let jobSelectorDialog: any = this.$refs.jobSelectorDialog;

        this.currentTargetJobPost = await jobSelectorDialog.show(jpxsPromise);

        if (!this.currentTargetJobPost) return;
      }
      jobsApi.orgs.labels
        .shortList(this.profile.id, this.currentTargetJobPost.id)
        .then(x => {
          this.loadProfile();
          G.showSnackbar(
            `${this.profile.firstname} was shortlisted`,
            "success"
          );
        })
        .catch(err => {
          G.log.error("Seeker.Profile.shortlist", {
            error: err,
            seekerId: this.profile.id,
            orgId: accounts.currentOrgId,
            jobPostId: this.currentTargetJobPost.id
          });
        });
    },
    ignore: async function() {
      if (!this.currentTargetJobPost) {
        let jpxsPromise = jobsApi.orgs.posts.getByOrgId(
          accounts.currentOrgId,
          false,
          models.jobPostStatusString.published
        );

        let jobSelectorDialog: any = this.$refs.jobSelectorDialog;
        this.currentTargetJobPost = await jobSelectorDialog.show(jpxsPromise);

        if (!this.currentTargetJobPost) return;
      }
      jobsApi.orgs.labels
        .ignore(this.profile.id, this.currentTargetJobPost.id)
        .then(x => {
          this.loadProfile();
          G.showSnackbar(`${this.profile.firstname} was ignored`, "default");
        })
        .catch(err => {
          G.log.error("Seeker.Profile.ignore", {
            error: err,
            seekerId: this.profile.id,
            orgId: accounts.currentOrgId,
            jobPostId: this.currentTargetJobPost.id
          });
        });
    },
    messageSeeker: function() {
      if (this.seekerThread && this.seekerThread.pagedMessages) {
        this.threadVisible = true;
      } else {
        let ntd: any = this.$refs.newThreadDialog;
        ntd.show(this.profile.id, this.profile.context.threads).then(thrd => {
          if (!thrd) return;
          this.$router.push({
            name: "Messages__Message",
            params: { passedThread: thrd }
          });
        });
      }
    }
  },
  computed: {
    messageButtonText: function() {
      return this.seekerThread == null
        ? ""
        : this.seekerThread.pagedMessages
        ? "Messages"
        : "Contact";
    },
    pageTitle: function() {
      if (!this.profile) return "Loading...";
      return this.profile.fullname;
    },
    seekerLabelsEx: function(): models.seekerLabelExtended[] {
      if (!this.profile) return [];
      return this.profile.seekerLabelsEx;
    },
    groupedSeekerLabelsEx: function() {
      let sp: models.seekerProfileExtended = this.profile;
      if (!sp.hasLabels) return [];
      return sp.groupedSeekerLabelsEx;
    },
    seekerVideo: function(): models.seekerVideo {
      let sp: models.seekerProfileExtended = this.profile;
      if (!sp.hasVideos) return null;
      return this.profile.seekerVideos[0];
    },
    vidThumbUriComputed: function(): string {
      if (this.seekerVideo) return this.seekerVideo.video.vidThumbUriComputed;
      else return "";
    },
    vidUri: function(): string {
      if (this.seekerVideo) return this.seekerVideo.video.vidUri;
      else return "";
    }
  },
  mounted() {
    this.initialLoadProfile();
  }
};
